import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)



export const routes = [
  {
    path: '/',
    component: () => import('@/views/index/index.vue'),
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/privacy-policy/index.vue'),
  },

  
]

const router = new Router({
    routes
})

export default router
