import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './rem'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import '@/styles/element-custom.scss'

import '@/styles/index.scss' // global css

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
